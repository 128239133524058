<template>
  <div class="container mb-60 baojia-box liucheng-top rounded-lg">
    <!-- 拼多多商品优惠 -->
    <v-card class="pa-10" flat v-if="ispinduoduo && pddinfo.goods_image_url">
      <v-row>
        <v-col xl="3" lg="3" md="3" sm="12" >
          <v-card
            width="300"
            height="300"
            flat
            color="#D9D9D9"
            class="text-center"
          >
            <div>
              <v-img width="300" :src="pddinfo.goods_image_url"></v-img>
            </div>
          </v-card>
        </v-col>
        <v-col xl="8" lg="8" md="8" sm="12">
          <div class="text-body-1 font-weight-bold">
            {{ pddinfo.goods_name }}
          </div>
          <v-card color="#F7F7F7" flat class="pa-4 mt-6 mb-6">
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("原价：") }}</div>
              <div class="grey--text">¥{{ pddprice.original_price }}</div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("到手价") }}</div>
              <div class="text-body-1 font-weight-bold text-sharered">
                ¥{{ pddprice.discount_price }}
              </div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("优惠：") }}</div>
              <div class="d-flex" v-if="pddinfo.has_coupon">
                <span class="couponC d-flex align-center">
                  <v-img
                    width="16"
                    src="@/assets/images/other/coupon@2x.png"
                  ></v-img>
                  <span class="mr-2 ml-2" v-if="pddinfo.unified_tags">{{
                    pddinfo.unified_tags[0]
                  }}</span>
                </span>
              </div>
              <div class="d-flex" v-else>
                <span class="couponC d-flex align-center">
                  <v-img
                    width="16"
                    src="@/assets/images/other/coupon@2x.png"
                  ></v-img>
                  <span class="mr-2 ml-2">{{ $t("暂无优惠券") }}</span>
                </span>
              </div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("返利金额") }}：</div>
              <div class="text-sharered">¥{{ pddprice.rebate_amount }}</div>
            </div>
          </v-card>
          <div>
            <v-btn
              rounded
              color="primary"
              class="mr-6"
              @click="openUrlpdd(pddinfo, 'pinduoduo')"
              >{{ $t("立即返利购买") }}
            </v-btn>
            <!-- <v-btn rounded color="primary" flat class=""
              >{{ $t("立即领券购买") }}
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- 淘宝商品优惠 -->
    <v-card class="pa-10" flat v-if="istaobao">
      <v-row >
        <v-col xl="3" lg="3" md="3" sm="12" v-if="info.pict_url">
          <v-card
            width="300"
            height="300"
            flat
            color="#D9D9D9"
            class="text-center"
          >
            <div>
              <v-img width="300" :src="info.pict_url"></v-img>
            </div>
          </v-card>
        </v-col>
        <v-col xl="8" lg="8" md="8" sm="12">
          <div class="text-body-1 font-weight-bold">{{ info.title }}</div>
          <v-card color="#F7F7F7" flat class="pa-4 mt-6 mb-6">
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("原价：") }}</div>
              <div class="grey--text">¥{{ info.zk_final_price }}</div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("到手价") }}</div>
              <div class="text-body-1 font-weight-bold text-sharered">
                ¥{{ info.price_after_coupon }}
              </div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("优惠：") }}</div>
              <div class="d-flex" v-if="coupon.coupon_info">
                <span class="couponC d-flex align-center">
                  <v-img
                    width="16"
                    src="@/assets/images/other/coupon@2x.png"
                  ></v-img>
                  <span class="mr-2 ml-2">{{ coupon.coupon_info }}</span>
                </span>
              </div>
              <div class="d-flex" v-else>
                <span class="couponC d-flex align-center">
                  <v-img
                    width="16"
                    src="@/assets/images/other/coupon@2x.png"
                  ></v-img>
                  <span class="mr-2 ml-2">{{ $t("暂无优惠券") }}</span>
                </span>
              </div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("返利金额") }}：</div>
              <div class="text-sharered">¥{{ info.commission }}</div>
            </div>
          </v-card>
          <div>
            <v-btn
              rounded
              color="primary"
              class="mr-6"
              @click="openUrl(coupon, 'tao')"
              >{{ $t("立即返利购买") }}
            </v-btn>
            <!-- <v-btn rounded color="primary" flat class=""
              >{{ $t("立即领券购买") }}
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- 1688商品优惠 -->
    <v-card class="pa-10" flat v-if="isali">
      <v-row>
        <v-col xl="3" lg="3" md="3" sm="12" v-if="info.imgUrl">
          <v-card width="300" flat color="#D9D9D9" class="text-center">
            <div>
              <v-img width="300" :src="info.imgUrl"></v-img>
            </div>
          </v-card>
        </v-col>
        <v-col xl="8" lg="8" md="8" sm="12">
          <div class="text-body-1 font-weight-bold">{{ info.title }}</div>
          <v-card color="#F7F7F7" flat class="pa-4 mt-6 mb-6">
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("原价：") }}</div>
              <div class="grey--text">¥{{ info.price }}</div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("老客户账号") }}</div>
              <div class="text-body-1 font-weight-bold text-sharered">
                ¥{{ info.oldCommission }}
              </div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("优惠：") }}</div>
              <div class="d-flex" v-if="info.yhqExt">
                <span class="couponC d-flex align-center">
                  <v-img
                    width="16"
                    src="@/assets/images/other/coupon@2x.png"
                  ></v-img>
                  <span class="mr-2 ml-2">{{ info.yhqExt.name }}</span>
                </span>
              </div>
              <div class="d-flex" v-else>
                <span class="couponC d-flex align-center">
                  <v-img
                    width="16"
                    src="@/assets/images/other/coupon@2x.png"
                  ></v-img>
                  <span class="mr-2 ml-2">{{ $t("暂无优惠券") }}</span>
                </span>
              </div>
            </div>
            <div class="mb-4 d-flex align-center">
              <div class="w-100">{{ $t("返利金额") }}：</div>
              <div class="text-sharered">¥{{ info.tkCommission }}</div>
            </div>
          </v-card>
          <div>
            <v-btn
              rounded
              color="primary"
              class="mr-6"
              @click="openUrl(info, '1688url')"
              >{{ $t("立即返利购买") }}
            </v-btn>
            <v-btn
              @click="openUrl(fl_url, '1688')"
              rounded
              color="primary"
              flat
              class=""
              >{{ $t("立即领券购买") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="12" class="text-center" v-show="loading">
        <img src="@/assets/images/other/loading.gif" width="58" class="mb-6" />
        <p>{{ $t("努力搜索中~") }}</p>
      </v-col>
    </v-row>
    <empty
      :describe="$t('暂无搜索内容~')"
      :imgSrc="emptysrc"
      v-if="
        (istaobao && !loading && !info.title) ||
        (ispinduoduo && !loading && !pddinfo.goods_name)
      "
    ></empty>
    <v-row class="mt-6" v-if="istaobao">
      <v-col cols="12">
        <div class="text-center d-flex align-center justify-center mb-4">
          <div>
            <v-img
              class="flex-inherit"
              width="28"
              src="@/assets/images/icon/icon_red_left.svg"
            ></v-img>
          </div>
          <span class="font-600 ml-4 mr-4 text-body-1 font-weight-bold">
            {{ $t("高返商品") }}
          </span>
          <div>
            <v-img
              class="flex-inherit mr-4"
              width="28"
              src="@/assets/images/icon/icon_red_right.svg"
            ></v-img>
          </div>
        </div>
      </v-col>
      <v-col
        cols="6"
        xl="3"
        lg="2"
        md="4"
        sm="4"
        v-for="(item, index) in datalist"
        :key="index"
        @click="goBuyFn(item)"
      >
        <div>
          <v-hover v-slot="{ hover }">
            <v-card
              class="mx-auto pa-4 cursor-pointer"
              :flat="hover ? false : true"
            >
              <v-card max-height="200" class="overflow-hidden" flat>
                <img :src="item.pict_url" width="100%" />
              </v-card>
              <p class="text-truncate mb-0 pt-1">{{ item.title }}</p>
              <div class>
                <span class="grey--text">{{ $t("剩余") }}：</span>
                <v-progress-linear
                  height="8"
                  :value="
                    (parseInt(item.coupon_remain_count) /
                      parseInt(item.coupon_total_count)) *
                    100
                  "
                  class="rounded-xl"
                  :rounded-bar="true"
                ></v-progress-linear>
              </div>
              <div class="d-flex justify-space-around">
                <div class="py-2 text-center">
                  <div class="">{{ $t("原价") }}</div>
                  <div class="font-weight-bold">¥{{ item.zk_final_price }}</div>
                </div>
                <div class="py-2 text-center">
                  <div class="">{{ $t("券") }}</div>
                  <div class="font-weight-bold">¥{{ item.coupon_amount }}</div>
                </div>

                <div class="py-2 text-center">
                  <div class="primary--text">{{ $t("返利") }}</div>
                  <div class="font-weight-bold primary--text">
                    ¥{{ item.commission }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-hover>
        </div>
      </v-col>
    </v-row>
    <!-- 拼多多 -->
    <v-row class="mt-6" v-if="ispinduoduo && pdddata.length>0">
      <v-col cols="12">
        <div class="text-center d-flex align-center justify-center mb-4">
          <div>
            <v-img
              class="flex-inherit"
              width="28"
              src="@/assets/images/icon/icon_red_left.svg"
            ></v-img>
          </div>
          <span class="font-600 ml-4 mr-4 text-body-1 font-weight-bold">
            {{ $t("高返商品") }}
          </span>
          <div>
            <v-img
              class="flex-inherit mr-4"
              width="28"
              src="@/assets/images/icon/icon_red_right.svg"
            ></v-img>
          </div>
        </div>
      </v-col>
      <v-col
        cols="6"
        xl="3"
        lg="2"
        md="4"
        sm="4"
        v-for="(item, index) in pdddata"
        :key="index"
        @click="openUrlpdd(item, 'pinduoduo')"
      >
        <div>
          <v-hover v-slot="{ hover }">
            <v-card
              class="mx-auto pa-4 cursor-pointer"
              :flat="hover ? false : true"
            >
              <v-card max-height="200" class="overflow-hidden" flat>
                <img :src="item.goods_image_url" width="100%" />
              </v-card>
              <p class="text-truncate mb-0 pt-1">{{ item.goods_name }}</p>
              <!-- <div class="mt-2 d-flex alignCenter">
                
                <i  style="color:red" class="el-icon-goods"></i>
                <div class="ml-2">{{$t('店铺')}}：{{item.mall_name}}</div>
              </div> -->
              <div class>
                
                <span class="grey--text">{{ $t("剩余") }}：</span>
                <v-progress-linear
                  height="8"
                  :value="
                    (parseInt(item.coupon_remain_quantity) /
                      parseInt(item.coupon_total_quantity)) *
                    100
                  "
                  class="rounded-xl"
                  :rounded-bar="true"
                ></v-progress-linear>
              </div>
              <div class="d-flex justify-space-around">
                <div class="py-2 text-center">
                  <div class="">{{ $t("原价") }}</div>
                  <div class="font-weight-bold">¥{{ item.pdd_price }}</div>
                </div>
                <div class="py-2 text-center">
                  <div class="primary--text">{{ $t("返利") }}</div>
                  <div class="font-weight-bold primary--text">
                    ¥{{ item.pdd_promotion_money }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-hover>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="tipdialog" width="450">
      <v-card class="pa-4">
        <v-card-title>{{ $t("温馨提示") }}</v-card-title>
        <v-card-text>
          <span>{{ $t("您暂未授权，请点击确定前往拼授权") }}</span>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            width="100"
            outlined
            class="text-center"
            color="primary"
            @click="tipdialog = false"
            >{{ $t("取消") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-center white--text"
            color="primary"
            @click="submitGo()"
            >{{ $t("确认") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import empty from "@/components/base/empty";
import { Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
export default {
  components: { empty },
  data() {
    return {
      url: "",
      platform: "",
      loading: false,
      info: {},
      pddinfo: {}, //拼多多商品详情
      pddprice: {}, //拼多多返利信息
      pdddata:[],//拼多多推荐商品
      coupon: {},
      datalist: [],
      fl_url: {},
      webtype: "",
      istaobao: false,
      isali:false,
      ispinduoduo: false,
      tipdialog:false,
      pddurl:"",//拼多多授权连接
      emptysrc: require("@/assets/images/other/wusousuo@2x.png"),
    };
  },
  watch: {
    $route: {
      handler() {
        this.$router.go(0);
      },
      deep: true,
    },
  },
  mounted() {
    this.url = this.$route.query.url;
    this.platform = this.$route.query.type;
    this.webtype = this.$route.query.webtype; //站点
    if (this.$route.query.id) {
      this.url = this.url + "&id=" + this.$route.query.id;
    }
    if (this.$route.query.source && this.$route.query.source == "chajian") {
      let furl = location.href;
      let U = furl.split("search-product?url=");
      let M = U[1].split("&type=1");
      window.open(decodeURIComponent(M[0]), "_self");
    } else {
      this.getgoodsInfo();
    }
  },
  methods: {
    openUrlpdd(info, type) {
      let param = {
        goods_sign: info.goods_sign,
        goods_id: info.goods_id,
      };
      this.$api.home.get_pddgoods_fanli(param).then((res) => {
        if (res.status == "success") {
          let url=''
          url=res.data.url
          window.open(url , '_blank')
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    // 打开优惠连接
    openUrl(info, type) {
      if (type == "1688url") {
        window.open(info.url, "_blank");
      } else if (type == "1688") {
        window.open(info.longClickUrl, "_blank");
      } else {
        window.open(info.click_url, "_blank");
      }
    },
    submitGo(url){
      window.open(this.pddurl, "_blank");
    },
    getQueryVariable(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    // 打开高返商品链接
    goBuyFn(val) {
      window.open(val.click_url, "_blank");
    },
    getgoodsInfo() {
      this.ispinduoduo=false
      this.istaobao=false
      this.isali=false
      this.loading = true;
      if (this.webtype == "pinduoduo") {
        this.ispinduoduo = true;
        // 如果是拼多多连接
        let param = {
          goods_url: this.url,
        };
        // let  options={
        //   lock: true,
        //     text: this.$t('获取返利中···'),
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.7)'
        // }
        // Loading.service(options);
        // let loadingInstance = Loading.service(options);
        this.$api.home.get_pddgoods_info(param).then((res) => {
          this.loading = false;
          // loadingInstance.close();
          if (res.status == "success") {
            this.pddinfo = res.data.goods_detail;
            this.pddprice = res.data.price_info;
            this.get_goods_pdd(res.data.goods_detail.cat_id);
          }else if(res.status == 'beian_error'){
            this.tipdialog=true
            this.pddurl=res.data.url
          }else {
            this.pddinfo = {};
            // this.$Toast({ content: res.msg, type: "error" });
          }
        });
      } else {
        if (this.url.indexOf("1688.com") != -1) {
          this.istaobao = false;
          this.isali=true
          let param = {
            goods_url: this.url,
            url_type: this.url.indexOf("qr.1688") > -1 ? "zkl" : "url",
          };
          this.$api.home.get_aligoods_info(param).then((res) => {
            this.loading = false;
            if (res.status == "success") {
              this.info = res.data.goods_detail;
              this.fl_url = res.data.fl_url;
              // this.get_goods(res.data.detail.cat_leaf_name)
            } else {
              this.info = {};
              this.$Toast({ content: res.msg, type: "error" });
            }
          });
        } else {
          this.istaobao = true;
          let param = {
            goods_url: this.url,
            platform: this.platform,
            url_type: this.url.indexOf("tb.cn") > -1 ? "tkl" : "url",
          };
          this.$api.home.get_goods_info(param).then((res) => {
            this.loading = false;
            if (res.status == "success") {
              this.info = res.data.detail;
              this.coupon = res.data.coupon;
              this.get_goods(res.data.detail.cat_leaf_name);
            } else {
              this.info = {};
              this.$Toast({ content: res.msg, type: "error" });
            }
          });
        }
      }
      this.loading = false;
    },
    //拼多多相关商品推荐
    get_goods_pdd(cat_id) {
      let param = {
        list_id: "",
        page_size: "20",
        keyword: "",
        cat_id: cat_id,
        page: 1,
      };
      this.$api.home.get_pddgoods_tuijian(param).then((res) => {
        if (res.status == "success") {
          this.pdddata=res.data.lists
        } else {
          this.pdddata = [];
        }
      });
    },
    get_goods(keyword) {
      let param = {
        keyword: keyword,
        page: 1,
      };
      this.$api.home.get_goods(param).then((res) => {
        if (res.status == "success") {
          this.datalist = res.data.lists;
        } else {
          this.datalist = [];
        }
      });
    },
  },
};
</script>
<style scoped>
.w-100 {
  width: 180px;
  color: #999999;
}
.text-sharered {
  color: #f63605;
}
.couponC {
  color: #2061c4;
}
.fan-box {
  border: 1px dashed #ff6600;
  color: #ff6600;
  font-size: 12px;
  padding: 0 4px;
}
.quan-box {
  border: 1px dashed #4caf50;
  color: #4caf50;
  font-size: 12px;
  padding: 0 4px;
}
.mt-2{
  margin-top: 10px;
}
.alignCenter{
  align-items: center;
}
.ml-2{
  margin-left: 8px;
}
</style>